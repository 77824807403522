/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
	      
      },
      finalize: function() {
        
        //AOS
        AOS.init();
        
        //Hamburger
  
			  var $hamburger = $(".hamburger");
			  var $nav = $(".hhd-nav-menu li a");
			  
			  $hamburger.on("click", function(e) {
			    $hamburger.toggleClass("is-active");
			    $("nav").toggleClass("hhd-nav-active");
					$("body").toggleClass("modal-open");
			  });
			  
			  $nav.on("click", function(e) {
				  $hamburger.removeClass("is-active");
				  $("nav").removeClass("hhd-nav-active");
					$("body").removeClass("modal-open");
			  });
			          
        //OwlCarousel
				
				$('#hhd-gallery').owlCarousel({
						autoplay: true,
						dots: false,
				    loop:true,
				    nav: false,
						responsiveClass:true,
						responsive:{
							0:{
								items:1,
        			},
							600:{
								items:2,
        			},
        			1000:{
	        			items:3,
        			}
						}
				});
				
	      // Smooth Scroll
				var scroll = new SmoothScroll('a[href*="#"]', {
					updateURL: false
				});
				
				// iUbenda
				(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; 				tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}						else{w.onload = loader;}})(window, document);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
